@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"


















































@import '~@sas-te/alfabeto-vue/src/scss/packages/vue-toasted'

.content
  background: $color-ice
  padding-bottom: 80px

.content-menu
  z-index: 999

.wrapper-login
  position: relative

  +mq-s--mf
    max-width: 430px !important

.wrapper-login-multi
  padding: 15px 0 160px 0
  margin-top: 50px

  +mq-s--mf
    max-width: 500px !important

  .login::before
    content: none

  .content
    background-color: #fff

// -------------------------------------------------------------------
// STATE
// -------------------------------------------------------------------
.is-link-disabled
  cursor: not-allowed
  opacity: 0.5
  text-decoration: none
  a
    pointer-events: none

.is-error-state
  font-size: 14px
  color: #EB0000

// -------------------------------------------------------------------
// TOASTED
// -------------------------------------------------------------------
.toasted
  width: 100%!important
  border-top-left-radius: 0 !important
  border-top-right-radius: 0 !important

  +mq-media--mf(601px)
    border-radius: 8px !important

  &.success
    background: #C5F0DE
    color: #0C633E

    &> svg
      stroke: #0C633E

  &.info
    background: #FFF4D0
    color: #794C00

    &> svg
      stroke: #794C00

.s-select-document
  margin-right: $size-s

.filter-area
  width: 100%
  min-height: 110px
  display: flex
  align-items: flex-end

  +mq-s--mf
    margin-right: 16px
